import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import {
  state,
  style,
  transition,
  animate,
  trigger,
  AUTO_STYLE,
} from "@angular/animations";
import { MenuItems } from "../../shared/menu-items/menu-items";
import { NavigationExtras, Router } from "@angular/router";
import { ApisService } from "../../services/apis.service";
import { DataFilterPipe } from '../../shared/elements/data-filter.pipe';

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger("slideInOut", [
      state(
        "in",
        style({
          transform: "translate3d(0, 0, 0)",
        })
      ),
      state(
        "out",
        style({
          transform: "translate3d(100%, 0, 0)",
        })
      ),
      transition("in => out", animate("400ms ease-in-out")),
      transition("out => in", animate("400ms ease-in-out")),
    ]),
    trigger("slideOnOff", [
      state(
        "on",
        style({
          transform: "translate3d(0, 0, 0)",
        })
      ),
      state(
        "off",
        style({
          transform: "translate3d(100%, 0, 0)",
        })
      ),
      transition("on => off", animate("400ms ease-in-out")),
      transition("off => on", animate("400ms ease-in-out")),
    ]),
    trigger("mobileMenuTop", [
      state(
        "no-block, void",
        style({
          overflow: "hidden",
          height: "0px",
        })
      ),
      state(
        "yes-block",
        style({
          height: AUTO_STYLE,
        })
      ),
      transition("no-block <=> yes-block", [animate("400ms ease-in-out")]),
    ]),
  ],
})
export class AdminComponent implements OnInit {
  public deviceType = "desktop";
  verticalNavType = "expanded";
  verticalEffect = "shrink";
  chatToggle = "out";
  chatInnerToggle = "off";
  innerHeight: string;
  isScrolled = false;
  isCollapsedMobile = "no-block";
  toggleOn = false;
  windowWidth: number;
  @ViewChild("searchFriends", /* TODO: add static flag */ { static: false })
  search_friends!: ElementRef;
  @ViewChild("toggleButton", /* TODO: add static flag */ { static: false })
  toggle_button!: ElementRef;
  @ViewChild("sideMenu", /* TODO: add static flag */ { static: false })
  side_menu!: ElementRef;
  config: any;

  activeOption = "Procesos";

  //modificar para que sean las notificaciones de la base de datos
  notifications: any = [];
  notificationsAll: any = [];

  showNotification!: boolean;

  constructor(
    public menuItems: MenuItems,
    private router: Router,
    private api: ApisService
  ) {
    const scrollHeight = window.screen.height - 150;
    this.innerHeight = scrollHeight + "px";
    this.windowWidth = window.innerWidth;
    this.setMenuAttributs(this.windowWidth);
    this.notifyUser();
    this.notifyUserAll();
  }

  ngOnInit() {
    /* this.logout() */
    setInterval(() => {
      /* Comprueba cada 0,1 segundo el enlace para saber en que pagina esta y asi cambiar el boton seleccionado del menu de navegacion*/
      switch (this.router.url) {
        case "/admin-dashboard": {
          this.activeOption = "Procesos";
          break;
        }
        case "/admin-task": {
          this.activeOption = "Tareas";
          break;
        }
        case "/app-calendar": {
          this.activeOption = "Calendario";
          break;
        }
        case "/admin-users": {
          if (this.windowWidth > 1000) {
            this.activeOption = "Clientes";
          } else {
            this.activeOption = "Buscar cliente";
          }
          break;
        }
        case "/admin-newuser": {
          if (this.windowWidth > 1000) {
            this.activeOption = "Clientes";
          } else {
            this.activeOption = "Añadir nuevo cliente";
          }
          break;
        }
        case "/configuration": {
          this.activeOption = "Configuración";
          break;
        }
        case "/info": {
          this.activeOption = "Estadísticas";
          break;
        }
        case "/app-invoice-box": {
          this.activeOption = "Facturas";
          break;
        }
        default: {
          if (this.router.url.substring(0, 18) === "/admin-userdetails" || this.router.url.substring(0, 12) === "/app-invoice"){
            if (this.windowWidth > 1000) {
              this.activeOption = "Clientes";
            } else {
              this.activeOption = "Buscar cliente";
            }
          } else {
            this.activeOption = "Procesos";
          }
          break;
        }
      }
    }, 100);
  }

  notifyUser() {
    this.api.notifyUser().subscribe((data: any) => {
      this.notifications = data.data;
    });
  }

  notifyUserAll() {
    this.api.notifyUserAll().subscribe((data: any) => {
      this.notificationsAll = data.data;
    });
  }

  calculateDaysToEvent(event: any) {
    const today = new Date();
    const eventDate = new Date(event.date);
    const diffTime = Math.abs(eventDate.getTime() - today.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  onClickedOutside(e: Event) {
    if (
      this.windowWidth < 610 &&
      this.toggleOn &&
      this.verticalNavType !== "offcanvas"
    ) {
      this.toggleOn = false;
      this.verticalNavType = "offcanvas";
    }
  }

  onResize(event: any) {
    this.innerHeight = event.target.innerHeight + "px";
    /* menu responsive */
    this.windowWidth = event.target.innerWidth;
    let reSizeFlag = true;
    if (
      this.deviceType === "tablet" &&
      this.windowWidth >= 768 &&
      this.windowWidth <= 1024
    ) {
      reSizeFlag = false;
    } else if (this.deviceType === "mobile" && this.windowWidth < 768) {
      reSizeFlag = false;
    }

    if (reSizeFlag) {
      this.setMenuAttributs(this.windowWidth);
    }
  }

  setMenuAttributs(windowWidth: any) {
    if (windowWidth >= 768 && windowWidth <= 1024) {
      this.deviceType = "tablet";
      this.verticalNavType = "offcanvas";
      this.verticalEffect = "push";
    } else if (windowWidth < 768) {
      this.deviceType = "mobile";
      this.verticalNavType = "offcanvas";
      this.verticalEffect = "overlay";
    } else {
      this.deviceType = "desktop";
      this.verticalNavType = "offcanvas";
      this.verticalEffect = "push";
    }
  }

  searchFriendList(event: any) {
    const search = this.search_friends.nativeElement.value.toLowerCase();
    let search_input: string;
    let search_parent: any;
    const friendList = document.querySelectorAll(
      ".userlist-box .media-body .chat-header"
    );
    Array.prototype.forEach.call(friendList, function (elements, index) {
      search_input = elements.innerHTML.toLowerCase();
      search_parent = elements.parentNode.parentNode;
      if (search_input.indexOf(search) !== -1) {
        search_parent.classList.add("show");
        search_parent.classList.remove("hide");
      } else {
        search_parent.classList.add("hide");
        search_parent.classList.remove("show");
      }
    });
  }

  toggleChat() {
    this.chatToggle = this.chatToggle === "out" ? "in" : "out";
  }

  toggleChatInner() {
    this.chatInnerToggle = this.chatInnerToggle === "off" ? "on" : "off";
  }

  onMobileMenu() {
    this.isCollapsedMobile =
      this.isCollapsedMobile === "yes-block" ? "no-block" : "yes-block";
  }

  onScroll(event: any) {
    this.isScrolled = false;
  }

  logout() {
    localStorage.clear();
    this.router.navigate(["auth/login"]);
  }

  logOutAlert() {
   /*  Swal.fire({
      title: "¿Estás seguro?",
      text: "Se cerrará la sesión",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0073aa",
      cancelButtonColor: "#d33",
      confirmButtonText: "Salir",
      cancelButtonText: "Cancelar",
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.logout();
      } else if (result.isDismissed) {
        console.log("cancelado");
      }
    }); */
    this.logout();
  }

  openNotification(state: boolean) {
    this.showNotification = state;
  }

  openUser(item: any) {
    const navData: NavigationExtras = {
      queryParams: {
        id: item.process.client.id,
      },
    };
    this.router.navigate(["admin-userdetails"], navData);
  }

  openOrder(id: any) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/admin-orderdetails', id])
    );
    window.open(url, '_blank');
  }
}
