<div id="pcoded" (window:resize)="onResize($event)" class="pcoded iscollapsed" theme-layout="vertical"
    vertical-placement="left" vertical-layout="wide" [attr.pcoded-device-type]="deviceType"
    [attr.vertical-nav-type]="verticalNavType" [attr.vertical-effect]="verticalEffect" vnavigation-view="view1">
    <div class="pcoded-overlay-box"></div>
    <div class="pcoded-container navbar-wrapper">
        <nav class="navbar header-navbar pcoded-header" header-theme="theme4" pcoded-header-position="fixed"
            style="padding-top: 0px;">
            <div class="navbar-wrapper" style="min-height: 75px;">
                <div class="superior-nav" [style.width]="this.windowWidth">
                    <div class="navbar-logo" navbar-theme="theme4" style="margin: 10px 20px 10px -20px; left: 20px;">
                        <a>
                            <img class="img-fluid" style="width: 100px;" src="assets/logo/Logo.png" alt="Theme-Logo" [routerLink]="['/']" />
                        </a>
                    </div>
                    <div *ngIf="this.windowWidth > 1108" class="right-nav">
                        <div class="notification-wrapper" style="margin-right: 10px;">
                            <div class="notification-btn" (click)="notifyUser(); openNotification(!showNotification)">
                                <span>
                                    <i class="ti-bell" style="margin-left: 5px; font-size: 1.5rem;"></i>
                                </span>
                            </div>
                            <div *ngIf="showNotification" class="notification-content"
                                (clickOutside)="openNotification(false)"><!--  [exclude]="'.notification-btn'" -->
                                <div class="notification-header" style="text-align: center;" data-bs-toggle="modal"
                                    data-bs-target="#not1Modal" (click)="notifyUserAll(); openNotification(false)">
                                    <span class="label">
                                        <p style="color: #fff;">Ver todas las notificaciones <i
                                                style="margin-left: 10px; margin-top: 5px;" class="ti-angle-right"></i>
                                        </p>
                                    </span>
                                </div>
                                <ng-container *ngFor="let item of notifications">
                                    <div class="notification-card" (click)="openOrder(item.process.id); openNotification(false)">
                                        <h6>{{item.description}}</h6>
                                        <p style="text-align: justify;" *ngIf="calculateDaysToEvent(item) !== 1">{{ calculateDaysToEvent(item) > 0 ? 'Faltan ' +
                                            calculateDaysToEvent(item) + 'dias' : 'Hoy'}}</p>
                                        <p style="text-align: justify;" *ngIf="calculateDaysToEvent(item) === 1">Mañana</p>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <ul class="nav-right" style="margin-right: 30px; margin-top: 10px;">
                            <li class="user-profile header-notification">
                                <a class="menu-top-btn" (click)="logOutAlert()">
                                    <span class="btn-log-out-header">Cerrar sesión</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="inferior-nav" style="left: 0px;">
                    <div style="margin-left: 30px;" class="navbar-container" *ngIf="this.windowWidth > 1108">
                        <ng-container *ngFor="let asideItems of menuItems.getAllTop()">
                            <ng-container *ngFor="let asideItem of asideItems.main ">
                                <div style="left: 0px;">
                                    <ul class="nav-left" *ngIf="asideItem.name !== 'Clientes'">
                                        <li class="user-profile header-notification " [routerLinkActive]="[ 'active']">
                                            <a [class]="asideItem.name === this.activeOption ? 'menu-top-btn-active' : 'menu-top-btn'"
                                                [routerLink]="['/', asideItem.state]" target="_self"
                                                (click)="activeOption = asideItem.name; toggleOn = false">
                                                <span>{{ asideItem.name }}</span>
                                            </a>
                                        </li>
                                    </ul>
                                    <ul class="nav-left" *ngIf="asideItem.name === 'Clientes'">
                                        <li class="user-profile header-notification" [routerLinkActive]="[ 'active'] ">
                                            <a [class]="'Clientes' === this.activeOption ? 'menu-top-btn-active' : 'menu-top-btn'"
                                                target="_self">
                                                <span>Clientes <i class="ti-angle-down"
                                                        style="margin-left: 5px;"></i></span>
                                            </a>
                                            <ul class="show-notification profile-notification"
                                                style="width: max-content; left: 15px;">
                                                <li class="client-list">
                                                    <a class="menu-top-btn" [routerLink]="['/admin-users']"
                                                        target="_self" style="margin-top: -5px;; margin-bottom: -5px;"
                                                        (click)="activeOption = 'Clientes'">
                                                        <span>Buscar clientes</span>
                                                    </a>
                                                    <a class="menu-top-btn" [routerLink]="['/admin-newuser']"
                                                        target="_self" style="margin-top: -5px; margin-bottom: -5px;"
                                                        (click)="activeOption = 'Clientes'">
                                                        <span>Añadir cliente</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>

                <!-- Boton que depliega el menu cuando se trata de un movil o si el ancho de la pantalla es menor o igual a 610px -->
                <div style="margin: 10px;" class="navbar-container" *ngIf="this.windowWidth <= 1108">
                    <a class="menu-top-btn link" (click)="toggleOn = !toggleOn" target="_self">
                        <i class="ti-menu"></i>
                    </a>
                </div>
            </div>
        </nav>


        <div class="pcoded-main-container " style="margin-top: 106px;">
            <div class="pcoded-wrapper">
                <!-- Menu lateral Movil/tablet -->
                <nav id="main_navbar "
                    [class]="this.windowWidth <= 1108 && toggleOn === true ? 'visible-navbar' : 'hidden-navbar'"
                    (clickOutside)="onClickedOutside($event)" sub-item-theme="theme2" active-item-style="style0">
                    <div class="main-navbar-items">
                        <ng-container *ngFor="let asideItems of menuItems.getAll()">
                            <ng-container *ngFor="let asideItem of asideItems.main ">
                                <div>
                                    <ul class="nav-right ">
                                        <li class="user-profile header-notification " [routerLinkActive]="[ 'active']">
                                            <a [class]="asideItem.name === this.activeOption ? 'menu-side-btn-active' : 'menu-side-btn'"
                                                [routerLink]="['/', asideItem.state]" target="_self"
                                                (click)="activeOption = asideItem.name; toggleOn = false">
                                                <span class="pcoded-micon "><i class="{{ asideItem.icon }} "></i></span>
                                                <span>{{ asideItem.name }}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </ng-container>
                        </ng-container>

                        <div>
                            <ul class="nav-right ">
                                <li class="user-profile header-notification ">
                                    <a class="menu-side-btn" (click)="logOutAlert()">
                                        <span class=" pcoded-micon "><i class="ti-back-left "></i></span>
                                        <span>Cerrar sesión</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>

                <div class="pcoded-content">
                    <div class="pcoded-inner-content" [style.margin-top]="this.windowWidth < 1108 ? '100px' : '0px'">
                        <div class="main-body">
                            <div class="page-wrapper">
                                <div class="page-body ">
                                    <router-outlet>
                                        <app-spinner></app-spinner>
                                    </router-outlet>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- Modal notificaciones -->
        <div class="modal fade" id="not1Modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="exampleModalLabel">Notificaciones</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <ul class="list-group">
                            <ng-container *ngFor="let not of notificationsAll">
                                <li class="list-group-item d-flex justify-content-between align-items-center fs-5">
                                    <span [style.color]="not.color" *ngIf="calculateDaysToEvent(not) !== 1">{{ calculateDaysToEvent(not) > 0 ? 'Faltan ' +
                                        calculateDaysToEvent(not) + ' días' : 'Es hoy'}}</span>
                                    <span [style.color]="not.color" *ngIf="calculateDaysToEvent(not) === 1">Es mañana</span>
                                    <div>
                                        <div class="link fs-5" (click)="openUser(not)"
                                            data-bs-dismiss="modal">
                                            <i class="ti-user"></i>
                                            <span> Ver usuario</span>
                                        </div>
                                        <div class="link fs-5 mt-1" (click)="openOrder(not.process.id)"
                                            data-bs-dismiss="modal">
                                            <i class="ti-envelope"></i>
                                            <span> Ver proceso</span>
                                        </div>
                                    </div>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
